.social_container{
    display: flex;
}
.social_pick_title{
    font-size: 14px;
    margin-bottom: 12px;
    margin-top: 32px;
}
.social_pick_flex{
    display: flex;
    flex-wrap: wrap;
   
}
.social_pick_item{
    border-radius: 8px;
    padding: 14px 16px;
    color: #38343E;
    border: 1px solid var(--lk-form-lines);
    display: flex;
    margin-right: 8px;
    margin-bottom: 8px;
    max-width: fit-content;
    transition: background 300ms;
    cursor: pointer;
}
.social_order_container{
    position: absolute;
    left: 0;
    top: 66px;
    width: 100%;
    border-radius: 8px;
    padding: 0 16px 14px 16px;
    background: white;
    z-index: 1;
    border: 1px solid var(--lk-form-lines);
}
.social_pick_item img{
    height: 20px;
    width: 20px;
    margin-right: 10px;
}
.social_pick_item div{
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 14px;
    color: #38343e;
}
input {
    border: 0;
    outline: 0;
}

.button_choose_app{
    display: flex;
    border: 2px solid #e7eff2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 20px 16px 4px;
    outline: 0;
    cursor: pointer;
    width: 280px;
    margin-bottom: 32px;
    transition: border 0.3s;
}
.btn_filter_choose_text{
    margin: 2px 16px 0px 16px;
  }
.just_content{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.just_content_auto{
    display: flex;
    justify-content: space-between;
}
.button_choose_app:hover{
    border : 2px solid #97c7ed
}
.btn_filter_choose_text{
    margin: 2px 16px 0px 16px;
}
.drop_arrow{
    height: 10px;
    margin-top: 4px;
}
.button_choose_text{
      font-size: 14px;
      color: black;
      margin: 2px 16px 0px 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 146px;
}
.social_url_description{
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 12px;
    color: #38343e;
    opacity: 0.5;
    margin-top: 8px;
}
.social_unversal{
    background: var(--yellow-bg);
    border-radius: 10px;
    padding: 16px 24px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    color: var(--black);
}
.social_add_btn{
    margin: 11.5px 11.5px 0 0;
}
.social_conditions{
    color : rgba(255, 98, 98);
     font-weight: 400;
    font-size: 12px;
    margin-top: 24px;
}
.social_create_btn{
    /* box-shadow: 0 4px 15px 0 rgba(246, 221, 0, 0.4); */
    background: var(--pink);
    border-radius: 4px;
    padding: 18px 74px;
    width: 100%;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: var(--white);
    padding: 18px;
    
    margin: 32px 0;
}
.social_history_border{
    border: 1px solid var(--lk-form-lines);
    border-radius: 8px;
    padding: 16px 24px;
    margin-top: 0px;
    min-height: 400px;
}
.social_history_title{
    font-family: var(--third-family);
    font-family: RobotoSlab-Bold;
    font-size: 21px;
    line-height: 133%;
    color: var(--black);
}
.social_history_item{
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
}
.social_history_item_title{
    font-family: var(--second-family);
    font-family: OpenSans-Semibold;
    font-size: 14px;
    color: var(--black);
    margin-bottom: 8.5px;
}
.social_history_item img {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    margin-right: 12px;
    margin-bottom: auto;
}
.social_item_url{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    color: var(--lk-blue);
    overflow: hidden;
        text-overflow: ellipsis;
        font-family: OpenSans-Semibold;
        white-space: nowrap;
    max-width: calc(100vw / 2 - 372px);
}
.social_item_progress{
    font-family: var(--font-family);
    font-family: OpenSans-Semibold;
    font-size: 12px;
    text-align: right;
    margin-top: 4px;
    color: #415464;
}
.social_empty{
  position: absolute;
  left: 50%;
  top : 50%;
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}


.tooltip_bg{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -9;
}
.apps_line{
    padding: 8px 16px 8px 20px;
    cursor: pointer;
}
.apps_line:hover{
    background: #EAF4FA;
}
.apps_drop_text{
    margin: 2px 0px 0px 0px;font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
    color: black;
    font-family: OpenSans-Regular;
}
.social_history_container{
     width: 100%;
     margin-left: 50px;
     margin-right: 24px;
}
.social_left{
    width: 100%;
}
.social_w_100{
    width: 100%;
    margin-bottom: 0;
}
.max_width_social{
    max-width: none;
}
.tooltip-not_social{
    left: 0;
    bottom: -132px;
    /* margin-right: 10px; */
    width: 288px;
    
    padding: 15px;
    display: block;
}
.apps_dropdown{
        background: #FFFFFF;
        border: 1px solid #E4ECEF;
        box-sizing: border-box;
        padding: 0px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        display: block;
        margin-left: -22px;
        position: absolute;
        z-index: 9999999;
        max-height: 428px;
        overflow-y: auto;
        width: 660px;
        padding-top: 6px;
        padding-bottom: 6px;
        cursor: auto;
        left: 20px;
        top: 56px;
    }
     .filter_dropdown{
        width: calc(100vw - 48px);
    }
@media (min-width: 768px) {
    .profile_toast{
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            width: 382px;
            bottom: 32px;
            top: initial;
            left: calc(100vw/2 - 148px);
            box-shadow: 0px 2px 3px rgb(0 0 0 / 10%), 0px 1px 1px rgb(0 0 0 / 5%);
        }
}
@media (max-width: 768px) {
    .social_container{
        display: block;
    }
   .apps_dropdown{
        width: calc(100vw - 48px);
        margin-left: -22px;
    }
    .filter_dropdown{
        margin: 36px 0px 0px -6px;
        top: auto;
        width: 280px;
    
    }
    .social_create_btn{
        width: calc(100vw - 72px);
    }
    .social_history_container{
        margin-left: 0;
        margin-top: 32px;
    }
    .social_item_url{
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: OpenSans-Semibold;
        white-space: nowrap;
        max-width: calc(100vw - 172px);
    }
    .social_history_item_title{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100vw - 172px);
    }
    
}

