.auth_tooltip{
    height: 100vh;text-align: left;
}
.close_auth_tooltip{
    cursor : pointer;height : 30px;width : 30px;margin-right : 8px;margin-top: -4px;right : 0;position : absolute;
}
.question_descr{
  position: absolute;
  background: white;
  top: 0;
  height: -webkit-fill-available;
  width: 100%;
  left: 0;
  z-index: 99999;
  padding: 20px;
}
.btn-get-money{

    text-transform: uppercase;
    border-radius: 8px;
    font-weight: 700;
    width: 100%;
    cursor: pointer;
    color: var(--main);
}
.bottom_left_ok{
    position: fixed;
    bottom: 16px;
  }
  .discount_banner {
    display: none;
  }
  .mobile-banner-sale {
    display: flex;
    height: 116px;
    margin-bottom: 20px;
    margin-left: -5%;
  }
  .mobile-banner-sale-promotion {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 116px;
    background: white;
    z-index: 99999;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 40px 0px;
  }
  .buy-coins__discount-2 {
    display: block;
   
  }
.discount_banner_container{
    background: #22232D;position: relative;z-index: 0;
    cursor: pointer;
}
.close_discount{
    cursor : pointer; height : 30px; width : 30px;margin-right : 8px; margin-top : 8px; right : 0; position : absolute;
}
.discount_white{
    color: white;
}
.table-banner-text{
    height: 116px;
    display: table;
    margin-right : 15%;
    margin-left: 16px;
}
.banner-text-mobile{
    font-size: 16px;
    margin-left: 16px;
    margin-right: 16px;
        vertical-align: middle;
    display: table-cell;
    font-weight : 900;
}
.banner-img-mobile{
    height: 116px;
}
.session_promotion_container{
    text-align: left;font-size: 14px;line-height: 150%;
}
.insta_problems_tooltip{
  background: #FFF3D6;
  padding: 16px;
  z-index: 99;
  border-top: 1px solid #DEDAD0;
  border-bottom: 1px solid #DEDAD0;
  border-left: 1px solid #DEDAD0;
  border-right: 1px solid #DEDAD0;
  display: block;
 
  margin : 16px 0 16px 0;
}
.session_banner{
    z-index: 99999;
  }
  .session_banner_line{
       position: absolute;
       left: 0;
       height: 20px;
       width: 100%;
       background: #FFF3D6;
       z-index: 9999;
       margin-top: 36px;
  }
  .session_banner_arrow{
    left: 50%;
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 900;
    overflow: hidden;
    background: #FFF3D6;
    border: 1px solid #DEDAD0;
    margin-top: 31px;
    transform: rotate(45deg);
  }
  .session_banner_container{
    background: #FFF3D6;
    padding: 32px 24px 22px 24px;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 99;
    border-top: 1px solid #DEDAD0;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    display: block;
    align-items: flex-start;
    cursor: auto;
    margin-top: 35px;
  }
  .session_success_icon{
    height: 24px;
    width: 24px;
    margin-right: 8px;
    margin-top: -3px;
  }
  .session_banner_title{
      font-family: OpenSans-SemiBold;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    font-weight: 700;
    opacity: 0.9;
    margin-bottom: 24px;
  }
  .session_banner_text{
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    font-weight: 500;
    opacity: 0.9;
    margin-bottom: 24px;
  }
  .session_banner_btn{
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #8641BF;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -10px;
  }
  
  .session_bottom_banner_container{
        width: 100%;
        bottom: 135px;
  }
  .session_line_bottom{
    bottom: 135px;
    height: 26px;
  }
  .session_arrow_bottom{
    bottom: 131px;
  }
  
  .session_bottom_banner_container_c {
    width: 100%;
    bottom: 165px;
  }
  
  .session_line_bottom_c {
    bottom: 165px;
  }
  
  .session_arrow_bottom_c {
    bottom: 161px;
  }
  @media (max-width: 768px) {
    .insta_problems_tooltip{
      background: #FFF3D6;
      padding: 16px;
      width: 100vw;
      z-index: 99;
      border-top: 1px solid #DEDAD0;
      border-bottom: 1px solid #DEDAD0;
      display: block;
      border-left: none;
      border-right: none;
      margin : 16px 0 16px -22px;
    }
    .session_banner_container{
      left: 0;
    }
    .question_descr{
      position: absolute;
      background: white;
      top: 0;
      height: auto;
      min-height: 100vh;
      width: 100vw;
      left: 0;
      z-index: 99999;
          padding: 20px;
  }
    .session_bottom_banner_container_c {
      width: 100vw;
      left: -22px;
      bottom: 115px;
    }
    .session_line_bottom_c {
      bottom: 115px;
    }
    .session_arrow_bottom_c {
      bottom: 111px;
    }
    .session_bottom_banner_container {
      width: 100vw;
      left: -22px;
      bottom: 135px;
    }
  
    .session_line_bottom {
      bottom: 135px;
    }
  
    .session_arrow_bottom {
      bottom: 131px;
    }
   
}
.tooltip_bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }
  .landscape_tooltip{
    z-index: 99;
  }
.bot_160{
  bottom: -160;
}
.tooltip__container {
  display: block;
  position: absolute;
  z-index: 100;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2); 
}
.withdrow-container{
  width: 320px;
  padding: 16px;
  margin-top: 6px;
  margin-bottom: 100px;
}
.tooltip__close {
  display: block;
  width: 24px;
  height: 24px;
  background: url(../custom_icons/close-tooltip.svg) no-repeat center;
  position: absolute;
  top: 12px;
  right: 42px;
  cursor: pointer;
  transition: .3s background-image; }
  
.tooltip__arrow {
  display: block;
  width: 50px;
  height: 25px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden; }
  .tooltip__arrow::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: white;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    left: 50%;
    box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.2); }
.tooltip-bottom {
  top: 40px;
  left: 0;
  padding: 0 21px 6px 21px; }
  .tooltip-bottom .tooltip__arrow {
    bottom: 100%; }
    .tooltip-bottom .tooltip__arrow::after {
      top: 100%; }
.tooltip-top {
  left: 0;
  bottom: 100%; }
  .tooltip-top .tooltip__arrow {
    top: 100%; }
    .tooltip-top .tooltip__arrow::after {
      top: 0; }
.tooltip-left {
  right: 100%; }
  .tooltip-left .tooltip__arrow {
    width: 25px;
    height: 50px;
    top: 0;
    left: 100%;
    transform: initial; }
    .tooltip-left .tooltip__arrow::after {
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      left: 0; }
.tooltip__input-error {
  padding: 15px 18px 14px 18px; 
}
.withdrow-data-container{
  width: 100%;
  position: relative;
  height: fit-content;
}
.withdrow-img{
  background: url(../custom_icons/withdrawal_card.svg) no-repeat center;
  height: 88px;
}
.withdrow-img:checked + .withdrow-img {
  background: url(../custom_icons/withdrawal_card_pressed.svg) no-repeat center;
  background-color: #37353d;
}
.withdrow-img-2 {
  background: url(../custom_icons/withdrawal_ya.svg) no-repeat center;
  height: 88px;
}
.withdrow-img-2:checked + .withdrow-img-2 {
  background: url(../custom_icons/withdrawal_ya_pressed.svg) no-repeat center;
  background-color: #37353d;
}
.withdrow-img-3 {
  background: url(../custom_icons/withdrawal_qiwi.svg) no-repeat center;
  height: 88px;
}
.withdrow-img-3:checked + .withdrow-img-3 {
  background: url(../custom_icons/withdrawal_qiwi_pressed.svg) no-repeat center;
  background-color: #37353d;
}
.reversed-arrow{
      transform: rotate(180deg);
      margin-top: -41px;
      margin-left: -16px;
}
.rate-content__tooltip-arrow-withdrow {
    left: initial;
    
}
.close_withdrow_icon{
  cursor : pointer; height : 25px; width : 25px;right : 0; position : absolute;margin-top:-16px;
}
.contianer_width{
  width: 100%;margin-top: 16px;
}
.bot_m_24{
  margin-bottom: 24px;
}
.bot_m_16{
  margin-bottom: 16px;
}
.top_m_16{
  margin-top : 16px
}
.send_req_btn{
  max-width: 100%;margin-top : 16px;margin-bottom: 16px;
}
.center_text{
  text-align: center
}
.success_picture{
  height: 140px; width : 140px;margin-bottom: 24px;
}
.success_text{
  font-family: OpenSans-Semibold;
  font-weight: 700; margin-top: 24px;
}
.margin_top_24{
  margin-top: 24px;
}
.btn_withdrow_req_3{
  max-width: 100%;margin-top : 16px;margin-bottom: 16px;
}


@media (max-width: 768px) {
  .total-tooltip__wrapper {
    padding: 25px 22px; }
  .total-tooltip__close {
    right: 22px; } }
.add-subscribe-form {
  display: flex;
  margin-top: 0px; }
  .add-subscribe-form__item {
    width: 100%;
    padding-right: 32px; }
    .add-subscribe-form__item:last-child {
      padding-right: 0; }
  .add-subscribe-form__label {
    margin-bottom: 14px; }
    .add-subscribe-form__label > .tooltip__container {
      margin-top: 26px;
      top: 100%;
      padding: 16px; }
  .add-subscribe-form__input {
    background-color: #fff;
    margin-top: 12px; }
  .add-subscribe-form__sex-buttons {
    margin-top: 12px;
    margin-bottom: 52px;
    width: 100%; }
  .add-subscribe-form__cost {
    display: block;
    margin-top: 6px; }
    .add-subscribe-form__cost b {
      font-weight: 900; }
  .add-subscribe-form__preview-btn {
    margin-top: 52px;
    text-align: right; }
   

@media (max-width: 768px) {
  .add-subscribe-form {
    margin-top: 21px;
    font-size: 14px;
    flex-direction: column; }
    .add-subscribe-form__item {
      width: 100%;
      margin-bottom: 21px;
      padding-right: 0; }
    .add-subscribe-form__input {
      margin-top: 8px; }
    .add-subscribe-form__preview-btn {
      display: none; }
    .add-subscribe-form__cost {
      position: absolute;
      bottom: 85px;
      margin-left: -22px;
      width: 100%;
      text-align: center; } }



@media (max-width: 768px) {
  .withdrow-container {
    width: 100%;
    z-index: 9;
  }
}
.picker_container{
  border: 1px solid #EBECF2;
  border-radius: 6px;
  margin-bottom: 24px;
  margin-top: 12px;
  width: 100%;
  display: flex;
}
.ym_img{
  border-radius: 6px 0 0 6px;
  width: 50%;
}
.qiwi_img{
  border-radius: 0 6px 6px 0;
  width: 50%;
}
.tooltip_bg_withdrow{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -9;
}
.ios_home_screen_plashka{
  opacity: 1;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 94vw;
  margin-left: 3vw;
  margin-bottom: 6vw;
  z-index: 999999;
  border-radius: 16px 16px 16px 16px;
  background: #FF8DED;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px 0px;
}
.android_home_screen_plashka {
  opacity: 1;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 999999;
  background: white;
  margin-left: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px 0px;
}
.android_logo{
  width: 44.9px;
  height: 40.26px;
  margin-right: 25px;
}
.android_home_screen_title {
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #000000;
}
.android_home_screen_text {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
  
}
.android_home_screen_button {
 
  height: 45px;
  margin-left: 26px;
  background: #4A8CED;
  border-radius: 2px;
  text-align: center;
      margin-top: 15px;
}
.android_home_screen_button_text {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
      margin-bottom: 0px;
      padding-top: 10px;
}

.ios_home_screen_title{
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}
.ios_home_screen_text{
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  letter-spacing: 0.02em;

  color: #000000;
}
.ios_home_image{
      height: 20px;
      width: 20px;
      margin-right: 6px;
      margin-left: 6px;
      margin-top: -6px;
}
.ios_home_arrow{
  position: absolute;
  width: 18px;
  height: 20px;
  background: #FF8DED;
  margin-top: -12px;
  transform: rotate(45deg);
  left: 0;
  margin-left: calc(50vw - 20px);
}
.close_ios{
  cursor : pointer; height : 25px; width : 25px;margin-right : 8px; margin-top : 8px; right : 0; position : absolute
}
.pad_25{
  padding : 25px;
}
.close_android{
  cursor : pointer; height : 30px; width : 30px;margin-right : 8px; margin-top : 8px; right : 0; position : absolute
}
.top_m{
  margin-top: 10px;
}
.align_left{
  text-align: left;
  text-transform: none;
}