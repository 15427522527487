.title-bottom-border{
    border-bottom: 4px solid var(--grey);
    padding-bottom: 30px;
    margin-bottom: 30px;
    font-size: 38px;
}

.invite-friends {
    display: flex;
    align-items: flex-start; 
}
.invite_bonus_yellow{
    border-radius: 8px;
    background: #fff3d6;
    padding: 20px;
    font-family: OpenSans-Bold;
        margin-top: 20px;
        display: flex;
        justify-content: center;
  }
  .invite_bonus_text{
    font-weight: 900;
    font-size: 26px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .invite_bonus_text_little {
    font-weight: 900;
    font-size: 16px;
    text-align: center;
  }
  .ivite_bonus_center_text{
        margin-right: 20px;
        margin-left: 20px;
  }
  .accepted_invites{
    background: #f7f8fd;
  }
  .inner_bonus{
    padding: 32px 24px 25px 24px;
  }
  .money_get_intarlike{
    font-size: 18px;
    line-height: 30px;
    font-family: OpenSans-Regular;
    margin-bottom: 10px;
  }
  .invite-bonus {
    border: 4px solid var(--grey);
    border-radius: 8px;
    padding: 32px 24px 25px 24px;
    width: 307px;
    margin-left: 32px;
    flex-shrink: 0; }
    .invite-bonus__heading {
      margin-bottom: 20px; 
}
.invite-friends-content{
  display: flex;
    justify-content: space-between;
    padding-right: 32px;
}
.h3 {
  font-size: 21px;
  font-weight: 900;
  text-transform: uppercase;
}
.bonus_rub{
  font-size: 42px;
}
.bonus_coins{
  font-size: 16px;
  font-weight: 900;
}
.bonus-left{
  width : 50%;
  margin-left : 0px;
}
.bonus-right{
  width : 50%;
  margin-left : 32px;
}
.top_m_16{
  margin-top : 30px;
  margin-bottom: 30px;
}
.top_m_10_s{
  margin-top: 10px;
}
.top_m_24_s{
  margin-top: 24px;
}
.ref_more{
  cursor: pointer;
  color: var(--main);
 
}
@media (max-width: 768px) {
  .top_m_16{
    margin-top : 16px;
    margin-bottom: 10px;
}
.h2, .h3 {
  font-size: 16px;
  text-transform: uppercase;
}
  .bonus_rub {
    font-size: 24px;
  }
  .bonus_coins {
    font-size: 14px;
    font-weight: 500;
  }
    .invite-bonus {
        margin-left: 0;
        margin-top: 30px;
        width: 100%; }
    .title-bottom-border{
      border-bottom: 0px solid var(--grey);
      padding-bottom: 0px;
      margin-bottom: 0px;
      font-size: 24px;
    }
    .bonus-left{
      width : 100%;
  
    }
    .bonus-right{
      width : 100%;
      margin-left: 0px;
  
    }
    .invite-friends-content{
      display: block;
  
        padding-right: 0px;
    }
    .my-top-tooltip{
      bottom: -190px;margin-right: 2px;width: 320px;
    padding: 15px;
    }
  }

  .share-block {
  
    width: 100%; }
    .share-block p {
      line-height: 20px; }
    .share-block__link {
      background-color: var(--grey);
      border-radius: 8px;
      padding-left: 20px;
      display: flex;
      
      border: 1px solid #EBECF1; }
    .share-block__input {
      border: none;
      background-color: transparent;
      width: 100%; }
    .share-block__copy-btn {
      padding: 15px 22px;
      width: initial;
      flex-shrink: 0; }
      
    .share-block__social {
      display: flex; }
      .share-block__social-item {
        border-radius: 8px;
        width: 88px;
        height: 48px;
        background-attachment: scroll;
        background-position: center;
        background-repeat: no-repeat;
        transition: .3s background-color; }
        .share-block__social-item_vk {
          background-color: #4B72A4;
          background-image: url(../pages_android/custom_views/custom_icons/social/vk.svg); }
          
        .share-block__social-item_fb {
          background-color: #3856A0;
          background-image: url(../pages_android/custom_views/custom_icons/social/fb.svg); }
          
        .share-block__social-item:first-child {
          margin-right: 15px; }
  
  @media (max-width: 768px) {
    .share-block__link {
      margin-top: 10px; } 
}




.top_32_m{
    margin-top : 32px;
}

.pad_0{
    padding: 0px;
}
.left_m_0{
    margin-left : 0px;
}
.person_img{
    height: 26px;opacity : 0.4;margin-top: -4px;
}
.block_work{
    display: block;
}
.line_h_23{
    line-height: 22px;
}
.line_h_30{
    line-height: 30px;
}
.m_0{
  margin-left : 0px;
}
.op_05{
  opacity: 0.5;
}