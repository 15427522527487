/*!
* Start Bootstrap - Agency v5.0.7 (https://startbootstrap.com/template-overviews/agency)
* Copyright 2013-2019 Start Bootstrap
* Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-agency/blob/master/LICENSE)
*/
.container{
  max-width: 90%;
}
/* @font-face {
  font-family: OpenSans-Regular; 
  src: url(../../../css_android/fonts/NotoSans-Regular.ttf); 
}

@font-face {
  font-family: OpenSans-Regular;
  src: url(../../../css_android/fonts/NotoSans-Regular.ttf);
}

@font-face {
  font-family: OpenSans-SemiBold; 
  src: url(../../../css_android/fonts/NotoSans-SemiBold.ttf); 
}
@font-face {
  font-family: OpenSans-Bold; 
  src: url(../../../css_android/fonts/NotoSans-Bold.ttf); 
} */
.body_back {
  overflow-x: hidden;
  font-family: OpenSans-Regular;
  /* Location of the image */
  background-image: url(../icons/bg_full.svg);

  /* Background image is centered vertically and horizontally at all times */
  background-position: center top;
  background-origin : padding-box;
  /* Background image doesn't tile */
  background-repeat: no-repeat;

  /* Background image is fixed in the viewport so that it doesn't move when
  the content's height is greater than the image's height */
  /*background-attachment: fixed;*/

  /* This is what makes the background image rescale based
  on the container's size */
  background-size: cover;

  /* Set a background color that will be displayed
  while the background image is loading */
  background-color: #292946;
}
.wave-mobile{
  display: none;
}
@media (max-width: 768px) {
  body_back{
    background-image: none;
  }
  .back-section{
    background-position: center top;
    background-origin : padding-box;
    /* Background image doesn't tile */
    background-repeat: no-repeat;

    /* Background image is fixed in the viewport so that it doesn't move when
    the content's height is greater than the image's height */
    /*background-attachment: fixed;*/

    /* This is what makes the background image rescale based
    on the container's size */
    background-size: cover;
  }
  .header-background{
    background-image: url(../icons/bg_mob_1.svg);
  }
  #about_us{
    background-image: url(../icons/bg_mob_2.svg);
    margin-top: -64px;
    padding-top: 64px;
    padding-bottom: 164px;
    margin-bottom: -164px;
    background-position: center center;
  }
  #how-it-works{
    background-image: url(../icons/bg_mob_3.svg);
    margin-top: -100px;
  }
  #reviews{
    background-image: url(../icons/bg_mob_4.svg);

  }
  #cases{
    background-image: url(../icons/bg_mob_5.svg);

  }
  .main-background{
    margin-top: -64px;
    padding-top: 64px;
  }
  .wave-mobile{
    background-image: url(../icons/bg_mob_2_wave.svg);
    display: block;
    height: 164px;
  }
}
.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.5;
  padding-top: 8px;
    padding-bottom: 8px;
}
.result_text{
  font-size: 16px;
  margin-top: 24px;
  line-height: 200%;
  text-align: center;
  color: #FFFFFF;

}
.result_text_land{
  display: block;
}
@media (max-width: 768px) {
  .result_text_land{
    display: none;
  }
}
.section_div {
  padding: 100px 0;
}

.section_div h2.section-heading {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
}
.text-center {
  text-align: center!important;
}
.section_div h3.section-subheading {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 75px;
  text-transform: none;
  font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

@media (min-width: 768px) {
  .section_div {
    padding: 150px 0;
  }
}

.btn {
  font-weight: 700;
}

.btn-xl {
  font-size: 18px;
  padding: 20px 40px;
}

.btn-primary {
  background-color: #fed136;
  border-color: #fed136;
}

.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  background-color: #fec810 !important;
  border-color: #fec810 !important;
  color: white;
}

.btn-primary:active, .btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, 0.5) !important;
}

::selection {
  background: #fed136;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

#mainNav {
 background-color:#262846;
 margin-top: 48px;
}


#mainNav .navbar-toggler {
  font-size: 12px;
  right: 0;
  padding: 13px;
  text-transform: uppercase;
  color: white;
  border: 0;
  margin-right: -34px;
}

#mainNav .navbar-brand {
  color: #fed136;
  font-family: 'Kaushan Script', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#mainNav .navbar-brand.active, #mainNav .navbar-brand:active, #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
  color: #fec503;
}

#mainNav .navbar-nav .nav-item .nav-link {
  font-size: 90%;
  margin-right: 32px;
  padding: 0.75em 0;
  letter-spacing: 2px;
  color: white;
  margin-top: 8px;
}

#mainNav .navbar-nav .nav-item .nav-link.active, #mainNav .navbar-nav .nav-item .nav-link:hover {
  color: #fed136;
}

@media (min-width: 992px) {
  #mainNav {
    padding-top: 25px;
    padding-bottom: 25px;
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    background-color: transparent;
  }
  #mainNav .navbar-brand {
    font-size: 1.75em;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  #mainNav .navbar-nav .nav-item .nav-link {
    padding: 1.1em 1em !important;
  }
  #mainNav.navbar-shrink {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #262846;
  }
  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.25em;
    padding: 12px 0;
  }
}

header.masthead {
  text-align: center;
  color: white;

  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

header.masthead .intro-text {
  padding-top: 150px;
  padding-bottom: 100px;
}

header.masthead .intro-text .intro-lead-in {
  font-size: 22px;
  font-style: italic;
  line-height: 22px;
  margin-bottom: 25px;
  font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
a {
  text-decoration: none;
  background-color: transparent;
}
header.masthead .intro-text .intro-heading {
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 25px;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

@media (min-width: 768px) {
  header.masthead .intro-text {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  header.masthead .intro-text .intro-lead-in {
    font-size: 40px;
    font-style: italic;
    line-height: 40px;
    margin-bottom: 25px;
    font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
  header.masthead .intro-text .intro-heading {
    font-size: 75px;
    font-weight: 700;
    line-height: 75px;
    margin-bottom: 50px;
    text-align: left;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
}
.left-stars{
  height: 100px;
  margin-left: 100px;
  margin-top: 50px;
}
.right-stars{
  height: 200px;
  margin-top: 70px;
  margin-right: 100px;
}
.wrap-account{
  color: white;
  background: #8641bf;
  padding: 20px;
  font-family: OpenSans-SemiBold;
  letter-spacing: 1px;
  border-radius: 8px;
outline:0;
}
.wrap-account:hover{
  opacity: 0.8;
  color: white;
}
.navbar-text{
  letter-spacing: 3px;
  opacity: 0.9
}
.wrap-account-navigation{
  color: white;
  background: #8641bf;
  font-family: OpenSans-SemiBold;
}
.top-32{
  margin-top: 32px;
}
.logo-white-img{
  text-align: left;
  width: 400px;
}
.text-left{
  text-align: left;
}

.service-heading {
  margin: 15px 0;
  text-transform: none;
}


#portfolio .portfolio-item {
  right: 0;
  margin: 0 0 15px;
}

#portfolio .portfolio-item .portfolio-link {
  position: relative;
  display: block;
  max-width: 400px;
  margin: 0 auto;
  cursor: pointer;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
  background: rgba(254, 209, 54, 0.9);
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
  opacity: 1;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
  font-size: 20px;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 20px;
  margin-top: -12px;
  text-align: center;
  color: white;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
  margin-top: -12px;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
  margin: 0;
}

#portfolio .portfolio-item .portfolio-caption {
  max-width: 400px;
  margin: 0 auto;
  padding: 25px;
  text-align: center;
  background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption h4 {
  margin: 0;
  text-transform: none;
}

#portfolio .portfolio-item .portfolio-caption p {
  font-size: 16px;
  font-style: italic;
  margin: 0;
  font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#portfolio * {
  z-index: 2;
}

@media (min-width: 767px) {
  #portfolio .portfolio-item {
    margin: 0 0 30px;
  }
}
.card-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.card-carousel .my-card {
  height: 20rem;
  width: 12rem;
  position: relative;
  z-index: 1;
  -webkit-transform: scale(0.6) translateY(-2rem);
  transform: scale(0.6) translateY(-2rem);
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  background: #2e5266;
  background: linear-gradient(to top, #2e5266, #6e8898);
  transition: 1s;
}

.card-carousel .my-card:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 100%;
  background-color: rgba(0,0,0,0.3);
  bottom: -5rem;
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.card-carousel .my-card:nth-child(0):before {
  content: '0';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.card-carousel .my-card:nth-child(1):before {
  content: '1';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.card-carousel .my-card:nth-child(2):before {
  content: '2';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.card-carousel .my-card:nth-child(3):before {
  content: '3';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.card-carousel .my-card:nth-child(4):before {
  content: '4';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.card-carousel .my-card:nth-child(5):before {
  content: '5';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.card-carousel .my-card:nth-child(6):before {
  content: '6';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.card-carousel .my-card:nth-child(7):before {
  content: '7';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.card-carousel .my-card:nth-child(8):before {
  content: '8';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.card-carousel .my-card:nth-child(9):before {
  content: '9';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.card-carousel .my-card.active {
  z-index: 3;
  -webkit-transform: scale(1) translateY(0) translateX(0);
  transform: scale(1) translateY(0) translateX(0);
  opacity: 1;
  pointer-events: auto;
  transition: 1s;
}

.card-carousel .my-card.prev, .card-carousel .my-card.next {
  z-index: 2;
  -webkit-transform: scale(0.8) translateY(-1rem) translateX(0);
  transform: scale(0.8) translateY(-1rem) translateX(0);
  opacity: 0.6;
  pointer-events: auto;
  transition: 1s;
}
.portfolio-modal {
  padding-right: 0px !important;
}

.portfolio-modal .modal-dialog {
  margin: 1rem;
  max-width: 100vw;
}

.portfolio-modal .modal-content {
  padding: 100px 0;
  text-align: center;
}

.portfolio-modal .modal-content h2 {
  font-size: 3em;
  margin-bottom: 15px;
}

.portfolio-modal .modal-content p {
  margin-bottom: 30px;
}

.portfolio-modal .modal-content p.item-intro {
  font-size: 16px;
  font-style: italic;
  margin: 20px 0 30px;
  font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.portfolio-modal .modal-content ul.list-inline {
  margin-top: 0;
  margin-bottom: 30px;
}

.portfolio-modal .modal-content img {
  margin-bottom: 30px;
}

.portfolio-modal .modal-content button {
  cursor: pointer;
}

.portfolio-modal .close-modal {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 75px;
  height: 75px;
  cursor: pointer;
  background-color: transparent;
}

.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}

.portfolio-modal .close-modal .lr {
  /* Safari and Chrome */
  z-index: 1051;
  width: 1px;
  height: 75px;
  margin-left: 35px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #212529;
}

.portfolio-modal .close-modal .lr .rl {
  /* Safari and Chrome */
  z-index: 1052;
  width: 1px;
  height: 75px;
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: #212529;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: '';
  background-color: #e9ecef;
}

.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline > li:after, .timeline > li:before {
  display: table;
  content: ' ';
}

.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #fed136;
}

.timeline > li .timeline-image h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
}

.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }
  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }
  .timeline > li .timeline-image h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.team-member {
  margin-bottom: 50px;
  text-align: center;
}

.team-member img {
  width: 225px;
  height: 225px;
  border: 7px solid #fff;
}

.team-member h4 {
  margin-top: 25px;
  margin-bottom: 0;
  text-transform: none;
}

.team-member p {
  margin-top: 0;
}

section#contact {

  background-repeat: no-repeat;
  background-position: center;
}

section#contact .section-heading {
  color: #fff;
}

section#contact .form-group {
  margin-bottom: 25px;
}

section#contact .form-group input,
section#contact .form-group textarea {
  padding: 20px;
}

section#contact .form-group input.form-control {
  height: auto;
}

section#contact .form-group textarea.form-control {
  height: 248px;
}

section#contact .form-control:focus {
  border-color: #fed136;
  -webkit-box-shadow: none;
  box-shadow: none;
}

section#contact ::-webkit-input-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#contact :-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#contact ::-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#contact :-ms-input-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

footer {
  padding: 25px 0;
  text-align: center;
}

footer span.copyright {
  font-size: 90%;
  line-height: 40px;
  text-transform: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

footer ul.quicklinks {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 50px;
  display: block;
  width: 50px;
  height: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: white;
  border-radius: 100%;
  outline: none;
  background-color: #212529;
}

ul.social-buttons li a:active, ul.social-buttons li a:focus, ul.social-buttons li a:hover {
  background-color: #fed136;
}

.about_us_plashka{
  width : 29%;
  border-radius: 16px;
  background: #404365;
  color: white;
  padding: 32px;
  text-align: left;
  background-image: linear-gradient(135deg, rgba(70, 75, 110, 0), rgba(55, 59, 87, 1));
}
.image-right-landscape{
  height: 56px;
position: absolute;
right: 0;
bottom: 0;
margin-right: -3%;
margin-bottom: 16px;
cursor: pointer;
}
.reviews_plashka{

  border-radius: 16px;
  background: #404365;
  color: white;
  padding: 32px;
  margin-right: 32px;
  width: 33%;
  text-align: left;
  background-image: linear-gradient(135deg, rgba(70, 75, 110, 0), rgba(55, 59, 87, 1));
}
.img-plashka{
  width: 25%;
}
.top-text-plashka{
  margin-top: 32px;
  font-size: 20px;
  line-height: 1.25;
  margin-bottom: 16px;
}
.top-text-plashka-0{
  margin-top: 0px;
  font-weight: 500;
  font-size: 20px;
}
.regular-text-plashka{
  line-height: 150%;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px;
}

.flex-text{
  display: flex;
  padding-top: 16px;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.3s;
}
.flex-text:hover{
  opacity: 1;
}
.flex-text-active{
  opacity: 1;
}
.image-instruction{
  display : flex; width : 50%;padding-top: 20px;margin-left : 16px;height : 600px;
}
/*.number-text:hover{
border: 2px solid rgba(255, 255, 255, 1);
color: rgba(255, 255, 255, 1);
}*/
.img-list-number{
  width : 40px; height: 50px;
  margin-right: 32px;
}
.list-text{
  font-size: 1rem;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.img-circle-plashka{
  width: 90%;
  margin-bottom: 16px;

}
.rounded-plashka{
  width : 29%;
  color: white;
  text-align: center;
}
.rounded-plashka-top-text{
  font-size: 20px;
      padding-left: 32px;
      padding-right: 32px;
margin-bottom: 24px;
margin-top: 24px;
      line-height: 24px;
}
.rounded-plashka-text{
  opacity: .7;
  padding-left: 32px;
  padding-right: 32px;
  line-height: 150%;
}
.earn-money-section{
  margin-top: 100px;
  color: white;
  text-align: center;
}
.earn-money-top-text{
  font-size: 36px;
    font-weight: bold;
    letter-spacing: 5px;
    margin-left: 5%;
    margin-right: 5%;
    font-family: OpenSans-Bold;
    padding-bottom: 32px;
    margin-top: 0px;
    padding-top: 0px;
    line-height: 1.25em;
}
.earn-plashka{
  position: relative;
  width : 22%;
  border-radius: 16px;
  background: #404365;
  color: white;
  padding: 0px 16px 16px 32px;
  text-align: left;
  background-image: linear-gradient(135deg, rgba(70, 75, 110, 0), rgba(55, 59, 87, 1));
}
.earn-img{

  width: 60px;
  position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 16px;
      margin-bottom: 16px;
}
.text-earn-plashka{
  width: 70%;
  margin-bottom: 36px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
      line-height: 150%;
}
.img-div-earn{
  width: 100%;
  text-align: right;

}
.container-flex-just-cont{
  display : flex;justify-content: space-between;
}
.right_24{
  margin-right: 24px;
}
.icon_24_img{
  width: 24px;
  height: 24px;
}
.width_100_black{
  width: 100%;
}
.stars-wrap-acc{
  margin-top: 100px;
  width: 100%;
  border-radius: 16px;
  background: #404365;
  color: white;
  height: 350px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(31, 32, 55, 0.4), rgba(38, 41, 69, 1));
}
.navbar-icon{
  height : 50px; width : 50px;margin-left: -8px;

  content:url(../icons/logo.svg);

}
.top-site-description{
  max-width : 45%; display : block;margin-right : 16px;
}
.universe{
  margin-right: -5%;
  display : flex; width : 60%;padding-top: 20px;margin-left : 16px;
}
.top-container{
  display : flex;justify-content: space-between;padding-top : 50px;
}
.intro-text-mobile{
  visibility: collapse;
  display: none;
}
.section-about{
      padding-top: 32px;
}
.container-about{
  display: flex;
  justify-content: space-between;
}
.rounded-container{
  margin-top : 72px; display: flex;
  justify-content: space-between;
}
.how-it-works-container{
  display : flex;justify-content: space-between; color : white;
}
.how-it-works-container-mobile{
  display: none;
}
.stars-text{
  margin-left: auto;
  width: 60%;
  margin-right: auto;
  margin-top: 64px;
  font-weight: 500;
  padding-top: 16px;
  font-size: 20px;
  display: block;
}
.last-element{
  text-align : center;color : white; margin-top : 0px;opacity : 0.7; padding-bottom : 182px;

}
.reviews-mobile{
  display: none;
}
.screens-container{
  display : flex;justify-content : center;
}
.screens-container-mobile{
  display: none;
}
.stars-wrap-acc-mobile{
  display: none;
}
.img-in-opened{
  display: none;
}
.bottom-button-wrap{
  display: none;
}
.pagination-img{
  width: 9px;
  margin-right: 24px;
  content:url(../icons/pagination_black.svg);
}
.pagination-img::after{

  content:url(../icons/pagination_black.svg);
}
.navbar-opened-plashka-mobile{
  display: none;
}
.pagination-container {
  margin-top: 48px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
  margin-left: -54px;
  height: 10px;
}
.pagination-container .active{
content:url(../icons/pagination_pink.svg);
}
.right-arrow{
  display: none;
}
@media (min-width: 1200px) {
  .section-about{
        padding-top: 0px;
  }
}
@media (min-width: 1300px) {
  .section-about{
        padding-top: 2%;
  }
}
@media (min-width: 1400px) {
  .section-about{
        padding-top: 4%;
  }
}
@media (min-width: 1500px) {
  .section-about{
        padding-top: 7%;
  }
}
@media (min-width: 1550px) {
  .section-about{
        padding-top: 8%;
  }
}
@media (min-width: 1600px) {
  .section-about{
        padding-top: 10%;
  }
}
@media (min-width: 1660px) {
  .section-about{
        padding-top: 13%;
  }
}
.m_bot_menu{
  font-size: 14px;
line-height: 17px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .navbar-opened-plashka-mobile{
    background: black;
    color: white;
    padding: 32px;
    text-align: left;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999999999;
   
    display: block;
    opacity:1;

  }
  .navbar-text-mobile{
    cursor: pointer;
    font-family: OpenSans-Regular;
letter-spacing: 2px;
  }
}
.menu_line{
  background: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 80%;
}
.left_m_8_menu{
  margin-left: 8px;
  height: 28px;
  width: 24px;
}
.menu_btn{
  background: #8641BF;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  padding: 16px;
  font-size: 14px;
line-height: 17px;
/* identical to box height */
text-align: center;
text-transform: uppercase;

color: #FFFFFF;
}
.close_menu_container{
  position: absolute;
  height: 48px;
  width: 48px;
  right: 0;
  top: 0;
}
.close_menu{
  margin-top: 16px;
  margin-left: 8px;
}
.menu_logo{
  height: 16px;
}
.bot_32_menu{
  margin-bottom: 32px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: rgba(255, 255, 255, 0.9);
  width: 24px;
  height: 2px;

}
.hamburger-box{
  width: 24px;
}
.hamburger--collapse .hamburger-inner::before{
  top: -7px;
}
.hamburger--collapse .hamburger-inner::after{
  margin-top: 6px;
}
.hamburger--collapse.is-active .hamburger-inner {
    background: rgba(255, 255, 255, 0.9);
    width: 24px;
    height: 2px;
}

.hamburger--collapse.is-active .hamburger-inner::after {
    background: rgba(255, 255, 255, 0.9);
    width: 24px;
    height: 2px;

}
.hamburger--collapse.is-active .hamburger-inner::before {
    background: rgba(255, 255, 255, 0.9);
    width: 24px;
    height: 2px;

}
.insta_get_like{
  display: none;
}
.navbar-height{
  height: auto;
}
.inta_get_likes_web{
      font-size: 20px;
      color: white;
      font-family: OpenSans-SemiBold;
      letter-spacing: 1.5px;
      display: block;
}
.flex_icons{
  display: flex;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.flex_icons_container{
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
}
.footer_icon_height{
  height: 24px;
  width: 24px;
  margin-top: 16px;
}
.footer_icons{
  margin-right: 24px;
  
}
.bot_icon_vk {
  margin-top: 0px;
}
.partner_plashka{
  position: relative;
  margin-top: 104px;
  margin-bottom: 104px;
  margin-left: 25%;
  width: 50%;
  border-radius: 16px;
  background: #3B3F5C;
  color: white;
  height: fit-content;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 54px 16px 54px 16px;
}
.partner_title{
  font-size: 20px;
  margin-top: 52px;
  margin-bottom: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}
.partner_text{
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-bottom: 32px;
  letter-spacing: 0.02em;
  font-family: OpenSans-Regular;
  color: rgba(255, 255, 255, 0.7);
}
.partner_btn{
  cursor: pointer;
    font-size: 14px;
    line-height: 17px;
    font-family: OpenSans-Regular;
    text-transform: uppercase;
    border: 2px solid #8546BC;
    box-sizing: border-box;
    padding: 16px 48px 16px 48px;
    border-radius: 8px;
    color: #FFFFFF;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
}
.partner_btn:hover{
  opacity: 0.5;
}
.black_navbar{
  width: 100%;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  padding: 12px calc(5% + 15px) 12px calc(5% + 15px);
}
.wrap_acc_btn{
  display: flex;
  padding: 10px 28px;
  width: fit-content;
  height: 40px;

}
.wrap_acc_btn_logo{
  height: 20px;
  width: 20px;
}
.black_navbar_text{
  font-size: 14px;
line-height: 16px;
color: #FFFFFF;
padding-top: 4px;

}
.right_32{
  margin-right: 32px;
}
.wrap_acc_btn_text{
  font-size: 12px;
line-height: 14px;
text-transform: uppercase;
margin-left: 8px;
margin-top: 4px;
color: #FFFFFF;
font-family: OpenSans-Regular;
}
.black_navbar_mobile{
  display: none;
}
@media (max-width: 768px) {
  .partner_plashka{
    margin-left: 32px;
    margin-right: 32px;
    width: auto;
  }
 
  .bot_icon_vk{
    margin-top: 32px;
  }
  .image-right-landscape{
    display: none;
  }
  .inta_get_likes_web {
    display: none;
  }
  .insta_get_like {
    margin-top: 56px;
    padding-top: 2px;
    margin-left: -10px;
    font-size: 12px;
    color: white;
    font-family: OpenSans-SemiBold;
    position: absolute;
    display: block;
    letter-spacing: 0.5px;
  }
  .navbar-height {
    height: 60px;
  }
  
  .navbar-icon{
    width: auto;
    height: 45px;
    margin-left: -24px;
    content:url(../icons/logo_mob.svg);
  }
  .my-button-wrap{
    visibility: collapse;
    display: none;
  }
  .top-site-description{
    visibility: collapse;
    display: none;
  }
  .universe{
     display : block;
     width: 100%;
     margin-left: 0px;
     padding-top: 32px;
     padding-left: 10%;
    padding-right: 10%;
  }
  .top-container{
    padding-top : 80px;
    display : block;
  }
  .intro-text-mobile{
    visibility: visible;
    display: block;
  }
  .intro-text{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .section-about{
    padding-top : 0px;
    padding-bottom: 0px;
  }
  .container-about{
    display: block;
margin-top: 64px;
  }
  .about_us_plashka{
    width: 100%;
    margin-top: 10%;
    padding: 10%;
  }
  .rounded-container{
    margin-top : 50px; display: block;

  }

  .rounded-plashka{
    width: 100%;
  }
  .how-it-works-container{
    display : none;
  }
  .how-it-works-container-mobile{
    display: block;
text-align: center;
color: white;
margin-top: 100px;
  }
  .flex-text{
    display: block;
    padding-top: 16px;
    opacity: 0.7;
    cursor: pointer;
  }
  .list-text{
    padding-top: 16px;
  }
  .earn-money-top-text{
    font-size: 24px;

letter-spacing: 2px;
  }
  .container-flex-just-cont{
    display : block;
  }
  .earn-plashka{
    width: 100%;
    margin-top: 32px;
    padding: 16px 16px 16px 32px;
  }
  .stars-wrap-acc{
    display: none;
  }
  .stars-wrap-acc-mobile{
    margin-top: 32px;
    width: 100%;

    color: white;

    text-align: center;
    display: flex;
    justify-content: space-between;

  }
  .left-stars{
    height: 100px;
    margin-left: 0px;
    margin-top: 0px;
  }
  .right-stars{
    height: 100px;
    margin-top: 0px;
    margin-right: 0px;
  }
  .stars-text-mobile{
    margin-top: 8px;
  font-size: 16px;
  margin-left: 32px;
  margin-right: 32px;
  line-height: 1.25;

  }
  /*.reviews-landscape{
    display: none;
  }*/

 
  .reviews-mobile{
    display: block;
  }
  .pagination-img{
    width: 7px;
    margin-right: 16px;
  }
  .pagination-container{
    display : flex; justify-content: center;margin-right: 36px;height: 10px;
    margin-left: -20px;
  }
  .right-arrow{
    display: block;
    cursor: pointer;
    width: 50px;
position: absolute;
top: 40%;
right: 0;
z-index: 5;
margin-right: -16px;
  }
  .screens-container{
    display: none;
  }
  .screens-container-mobile{
    display: block;
  }
  .navbar-opened-plashka{
    border-radius: 16px 0px 16px 16px;
    background: #404365;
    color: white;
    padding: 32px;
    text-align: left;
    background-image: linear-gradient(135deg, rgba(70, 75, 110, 0), rgba(55, 59, 87, 1));
    position: relative;

  }

  .transformable-profiles-navbar {
    -webkit-transition: height 100ms linear;
    -moz-transition: height 100ms linear;
    -o-transition: height 100ms linear;
    -ms-transition: height 100ms linear;
    transition: height 100ms linear;
    transition:0.1s linear all;
}
  .img-in-opened{
    position: absolute;
    opacity: 0.7;
    right: 0;
    display: block;
    margin-right: 16px;
margin-top: -16px;
cursor: pointer;
  }
  .bottom-button-wrap{
    position: fixed;
    text-align: center;
    bottom: 0;

    padding-top: 1px;
    background: rgba(38, 40, 70, 0.9);
    height: 80px;
    width: 100%;
    display: flex;
    z-index: 9999999;
  }
  .fixed-bot-btn{
    margin-left: auto;    margin-top: 16px;
margin-bottom: 16px;
      margin-right: auto;
          padding-top: 12px;
          width: 80%;
  }

}
.mobile-how-works-title{
  font-size: 20px;
/* font-weight: 900; */
/* letter-spacing: 5px; */
font-weight: bold;
letter-spacing: 5px;
margin-left: 5%;
margin-right: 5%;
font-family: OpenSans-Bold;
padding-bottom: 32px;
margin-top: 0px;
padding-top: 0px;
line-height: 1.25em;
}
.subscribe {
  border-bottom: 4px solid #F7F8FD;
  padding-bottom: 27px;
  margin-bottom: 18px; }
  .subscribe__nav {
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
    .subscribe__reward {
      background-color: #FEF3D6;
      border-radius: 20px;
      font-weight: 700;
      font-size: 14px;
      padding: 6px 18px;
      margin-left: 16px; }
      .subscribe__nav-btns {
        margin-left: auto; }
        .accounts {
          overflow-x: hidden;
          margin-right: -42px; }
          .accounts__list {
            display: flex;
            padding-bottom: 15px;
            justify-content: space-between;
            transition: .7s transform; }
            .accounts__item {
              width: 307px;
              margin-right: 32px;
              border: 4px solid #F7F8FD;
              border-radius: 8px;
              position: relative;
              flex-shrink: 0;
              font-size: 14px;
              padding: 20px 30px 15px 30px;
              display: flex;
              flex-direction: column;
              transition: .3s box-shadow; }
              .accounts__item:hover, .accounts__item:focus {
                box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.2); }
                .accounts__item:last-child {
                  margin-right: 42px; }
                  .accounts__photo {
                    border-radius: 100%;
                    margin: 0 auto;
                    margin-bottom: 24px; }
                    .accounts__login {
                      font-weight: 900;
                      margin-bottom: 22px;
                      text-align: center; }
                      .accounts__close {
                        background-color: transparent;
                        /* background-image: url(../icons/close.svg); */
                        background-repeat: no-repeat;
                        background-position: center;
                        background-attachment: scroll;
                        transition: .4s background-image;
                        height: 24px;
                        width: 24px;
                        position: absolute;
                        top: 8px;
                        right: 8px; }
                        .accounts__close:hover, .accounts__close:focus {
                          /* background-image: url(../icons/hover/close.svg); } */}
                          .accounts__desc {
                            margin-bottom: 13px; }
                            .accounts__btn {
                              margin-top: auto; }
                              .accounts__btn:hover, .accounts__btn:focus {
                                color: var(--purple-hover);
                                background-color: #F7F8FD; }
.relative{
  position : relative;
}
.bot_0{
  margin-bottom : 0px;
}
.padding_0{
  padding : 0px;
}
.btn_togle{
  margin-right : -15px;margin-top : -2px;
}
.btn_promote{
  margin-bottom : 8px; margin-right : 0px; letter-spacing : 1px; border-radius : 8px;
}
.opacity_08{
  opacity: 0.8;
}
.bot_16{
  margin-bottom : 16px;
}
.it_works_container{
  max-width : 50%; display : block;margin-right : 16px; padding-top : 100px;
}
.it_works_text{
  font-size : 32px;font-family: OpenSans-Bold;
    letter-spacing: 5px;
}
.sign_color{
  color : #c397ff
}
.left_70{
  margin-left : 70px;
}
.bot-32{
  margin-top : -32px;
}
.width_50{
  width : 50%;
}
.back_div{
  padding-bottom : 0px; padding-top : 70px;
}
.color_white{
  color:white;
}
.pad_bot_0{
  padding-bottom : 0px;
}
.top_0{
  margin-top : 0px;
}
.right_0{
  margin-right : 0px;
}
.text_cases{
  text-align : center;color : white;
}
.insta_logo{
  width : 32px; margin-top  : 32px;
}
.our_insta_text{
  color : #c397ff; margin-top  : 16px; font-size : 14px;cursor : pointer;
}
.many_people{
  margin-top : 100px;margin-bottom : 0px;
}
.top_64{
  margin-top : 64px;
  margin-bottom: 24px;
  font-size: 24px;
line-height: 200%;
}
.before_screen{
  display : block; width : 30%; margin-right : 24px;text-align : center;color : white;
}
.before_text{
  opacity : 0.5; font-size : 14px;
}
.width_100{
  width : 100%
}
.after_screen{
  display : block; width : 30%;margin-left : 24px;text-align : center;color : white;
}
.after_text{
  opacity : 0.5; font-size : 14px;
}
.swipe_mobile{
  display : block; width : 100%; margin-right : 24px;text-align : center;color : white; position : relative;
}
.opacity_05{
  opacity : 0.5
}
.block_display{
  display: block
}
.top_16{
  margin-top : 16px;
}
.yandex_m{
  position:absolute; left:-9999px;
}
.container {
  width: 100%;
  max-width: 90%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
img {
  vertical-align: middle;
  border-style: none;
}
.cases_title{
  font-size: 40px;
  font-family: OpenSans-Bold;
}
.text-uppercase {
  text-transform: uppercase!important;
}
.ml-auto, .mx-auto {
  margin-left: auto!important;
}
.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
.navbar-text {
  display: inline-block;

}
.navbar-dark .navbar-toggler {
  color: rgba(255,255,255,.5);
  border-color: rgba(255,255,255,.1);
}
.collapse:not(.show) {
  display: none;
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}
.navbar {
  display: -ms-flexbox;
  display: flex;
 
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  padding: .5rem 1rem;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.navbar>.container, .navbar>.container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.nav_container_new{
  width: 100%;
}
/* @media (max-width: 992px){
  .container {
    max-width: 960px;
  }
    
} */
.other_social_container{
  background: #4B2F60;
  display: flex;
  width: 100%;
  position: relative;
  
}
.other_icon{
  width: 50%;
}
.other_plashka{
  display: block;
  width: 50%;
  position: relative;
}
.center_other{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.other_title{
  font-family: OpenSans-Bold;
font-size: 24px;
line-height: 29px;

color: #FFFFFF;
}
.left_unset{
  margin-left: unset;
  margin-top: 32px;
}
.footer{
  background: black;
  width: 100%;
  padding: 28px 80px;
  display: flex;
  justify-content: space-between;
}
.instarlike_year{
  margin-top: 16px;
}
.text_opacity{
  
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;

  opacity: 0.5;
}
.stories_land_text{
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4B2F60;
  margin : 0px 0px 32px 0px;
  max-width: 600px;
}
.stories_container{
  background: white;
  padding-bottom: 113px;
  padding-top: 113px;
  
}
@media (max-width: 768px){
  .cases_title{
    font-size: 22px;
    font-family: OpenSans-Bold;
  }
  .stories_container{
    background: white;
    padding-bottom: 108px;
    padding-top: 80px;
    
  }
  .black_navbar{
    display: none;
  }
  .footer{
    padding: 36px 24px;
    display: block;
  }
  .footer_32{
    margin-top: 32px;
  }
  .footer_last{
    margin-bottom: 80px;
  }
  .stories_land_text{
    padding-left: 32px;
    padding-right: 32px;
  }

  .other_social_container{
    display: block;
    padding-bottom: 80px;
  }
  .other_icon{
    width: 100%;
  }
  .other_plashka{
    width: 100%;
  }
  .center_other{
    position: unset;
    transform : none;
    top : 0;
    left: 0px;
    margin: -40px 24px 0px 24px;
  }
  .black_navbar_mobile{
    display: flex;
    padding: 12px 24px;
  }
  .jus_cont{
    display: flex;
    justify-content: space-between;
  }
  .left_m-8{
    margin-left: 8px;
  }
}
@media (min-width: 992px){
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
  .navbar-expand-lg .navbar-toggler {
      display: none;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
}
  .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
/* @media (max-width: 768px){
  .container {
    max-width: 720px;
  }
}
@media (max-width: 576px){
  .container {
    max-width: 540px;
  }
} */
@media (max-width: 380px) {
  .navbar-icon{
    height: 35px;
  }
}
.menu_icon_stories_land{
  height: 16px;
  width : 16px;
  margin-left: 8px;
  margin-top: 4px;
  margin-right: 32px;
}

.center_stories{
  position: relative;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.stories_land_icon{
  margin: 0px 0px 32px 0px;
  
}
.stories_land_title{
  font-family: OpenSans-Bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #4B2F60;
  margin : 0px 32px 32px 32px;
}

.find_more_stories{
  color: #8541BD;
  margin: 49px 32px 32px 0px;

}
