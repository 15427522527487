.left_m_8{
    margin-left: 8px
}
.confirm_container{
    display: block;
        position: absolute;
      background: white;
      width: 100%;
      left: 0;
      height: 100%;
      max-width: 600px;
      
  }
  .confirm_prices_container{
    background-color: var(--grey);
    border-radius: 8px;
    padding-left: 20px;
    display: block;
    border: 1px solid #EBECF1;
    padding: 16px 16px 16px 16px;
  }
  
  .flex_confirm{
    display: flex;
  }
  .just_space {
    justify-content: space-between;
    width: 100%;
  }
  .confirm_avatar{
    border-radius: 160px;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    margin-top: -2px;
  }
  .confirm_paddings{
    padding: 16px;
  }
  .line_throught{
    font-size: 14px;
    line-height: 200%;
    text-decoration-line: line-through;
    margin-right: 8px;
    color: #353845;
  
    opacity: 0.5;
  }
  .confirm_text{
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    opacity: 0.9;
    margin-bottom: 16px;
  }
  .confirm_bold{
    font-weight: 900;
  }
  .confirm_buy_btn{
    background: var(--pink);
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #FFFFFF;
    
    text-align: center;
        padding-bottom: 16px;
      padding-top: 16px;
      position: fixed;
      bottom: auto;
      left: 272px;
      right: 16px;
      max-width: 568px;
  }
  .confirm_buy_btn:active{
    opacity: 0.7;
  }
  .confirm_price{
    color: #E23600;
    margin-top: 4px;
    margin-bottom: 0px;
  }
  .back_confirm_icon{
        height: 36px;
        width: 36px;
        transform: rotate(180deg);
      margin-left: 4px;
      margin-top: -4px;
}
.buy-buttons-container{
    position: relative;
    border-radius: 8px;
}
.buy-grid-container{
    display: flex;
    height: 100px;
}
.buy-title-container{
     position: absolute;
     font-size: 20px;
     text-align: center;
     left: 50%;
     transform: translateX(-50%);
     font-weight: 900;
     margin-top: 30px;
     width: 90%;
     text-transform: uppercase;
}
.btn-buy-coins{
    margin-bottom: 16px;
    height: 50px;
    width: 90%;
    margin-left: 5%;
    border-radius: 8px;
    display: flex;
    opacity: 1;
    cursor: pointer;
    font-size: 14px;
}

.left-buy-coins{
  background: var(--pink);
  width: 60%;
  text-align: center;
  color: white;
  text-transform: uppercase;
  border-radius: 8px 0px 0px 8px;
}
.left-text-buy{
    font-size: 16px;
    font-weight: 700;
    margin-top: 13px;
    font-family: OpenSans-Bold;
}
.right-text-buy-1{
   color: #7f6924;
   text-decoration: line-through;
   margin-top: 5px;
   margin-bottom: 0px;
   
}
.right-text-buy-2 {
  color: black;
  font-weight: 900;
  font-family: OpenSans-Bold;
}
.right-buy-coins{
  width: 40%;
  display: block;
  text-align: center;
  background: #ffd348;
  border-radius: 0px 8px 8px 0px;
}
.buy-another-container{
background: #F7F8FD;
position: relative;
display: block;
padding-bottom: 16px;
border-radius: 8px;
}
.input-another-container{
margin-left: 5%; margin-right : 5%;
margin-top : 16px;
width: 90%;
margin-bottom: 16px;font-size: 16px;
}
.input-another-container-order {
margin-left: 5%;
width: 80px;
margin-top: 16px;
background-color: var(--grey);
}
.buy-description-title{
font-size: 18px;
font-weight: 900;
margin-top: 36px;
margin-bottom: 16px;
text-transform: uppercase;
}
.buy-descr-coins{
display: flex;
margin-top: 8px;
justify-content: space-between;
border-bottom: 2px solid var(--grey);
}
.calc-likes-checkbox{
content: '';
display: inline-block;
vertical-align: middle;
border: 1px solid #F5F5F8;
transition: .3s border-color;
border-radius: 8px;
width: 32px;
height: 32px;
margin-right: 10px;
background-color: white;
    margin-left: 16px;
    margin-top: 16px;
}
.calc-likes-checkbox-order{
    content: '';
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #F5F5F8;
    transition: .3s border-color;
    border-radius: 8px;
    width: 34px;
    height: 34px;
    border: 1px solid #F5F5F8;
    margin-right: 10px;
    background-color: var(--grey);
   
    margin-top: 16px;
}
.buy-another-right-text{
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 7%;
    margin-top: 24px;
    font-size: 16px;
}
.full_width_m{
    width: 100%
}
.get_followers_settings{
    display: flex;
    color: var(--blue);
    cursor: pointer;
     text-transform: uppercase;
     outline:0;
     margin-top: 36px;
}
.get_followers__arrow-menu {
    background: url(../custom_icons/blue_down.svg) no-repeat center;
    width: 14px;
    height: 8px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    rotate: 180deg;
    transition: .4s transform;
    transform: rotate(180deg); 
}
.result_payment_btn{
  background: var(--pink);
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
  
  text-align: center;
  padding: 16px 32px;
  margin-top: 32px;
  position: fixed;
  bottom: auto;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  max-width: 568px;
}
.asdfasg{
  font-size: 16px;
    margin-left: 16px;
    font-family: 'OpenSans-Semibold';
    margin-bottom: 16px;
}
.input-another-container_shop{
  margin-top: 8px;
  font-size: 16px;
  padding: 16px;
  height: 48px;

}
.line-calc-line {
    background: #8080804f;
    height: 2px;
        margin-top: 36px;
        margin-bottom: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .text-calc {
    margin-top: 24px;
  }
  .text-container-calc{
    display: flex
  }
  .line-calc{
    display: block;
    
    
  }
  .line-calc-order {
    
    display: flex;
    justify-content: space-between;
    height: 60px;
  }

  .calc-checked{
    background-image: url(../custom_icons/check.svg);
    background-repeat: no-repeat;
    background-position: center;
    
  }
.font_16{
    font-size: 16px;
}
.arrow_rot{
    margin-top : 6px;margin-left : 8px;
}
.bot_m_0{
    margin-bottom: 0px;
}
.width_80{
    width: 80px
}
.m_dif{
    margin-left : 16px;margin-right : 16px; margin-bottom: 16px;
}
.balance_from_money{
  background: #FFF3D6;
  border-radius: 8px;
  width: -webkit-fill-available;
  margin: 0px 16px 16px 16px;
  padding: 16px;
}
.from_balance_text{
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.from_balance_check{
  min-width: 32px;
  min-height: 32px;
  margin-top: 0px;
  margin-left: 0px;
}
.new-up-balance{
  max-width: 600px;
}
.from_balance_desc{
  margin-top: 19px;
  display: flex;
  justify-content: space-between;
}

.buy_container{
  height: 176px;
  padding-left: 90px;
}
.buy_plashka{
  background: #F7F8FD;
  width: 140px;
  height: 100%;
  position: relative;
}
.left_right_plashka{
  margin-top: 8px;
  height: calc(100% - 16px);
}
.left_plashka{
  border-radius: 4px;
}
.right_plashka{
  border-radius: 0px 4px 4px 0px;
}
.center_plashka{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 9;
  width: 140px;
  border-radius: 4px;

}
.profile_shop_img{
  height: 10px;
  width: 9.5px;
  margin-top: 2px;
}
.buy_plashka_title{
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #23252E;
  margin-top: 16px;
  font-family: OpenSans-Semibold;
}
.buy_plashka_desc{
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 4px;
}
.decoration_line{
  text-decoration-line: line-through;
}
.shop_sale_text{
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin-top: -16px;
  margin-bottom: 6px;
}
.desc_container_buy{
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6px;
  margin-top: 4px;
  margin-bottom: 8px;
  width: -moz-fit-content;
}
.price_buy{
  color: var(--tiktok_red);
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 2px;
  margin-left: 4px;
}
.btn_buy_left{
  position: absolute;
  bottom: 12px;
  width: 100%;
}
.top_16{
  margin-top: 16px;
}
.btn_buy{

  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background: white;
  color: black;
  border-radius: 4px;
  border-right: 4px solid var(--tiktok_red);
  border-left: 4px solid var(--tiktok_blue);
 
  width: fit-content;
  width: -moz-fit-content;
  
}
.shop_price_text_left{
  font-weight: 400;
  font-size: 12px;
  color: #353845;
  opacity: 0.5;
  margin-top: 4px;
  text-decoration: line-through;
}
.shop_buy_btn_new{
  font-family: OpenSans-Bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: var(--blue);
}
.more_coins_shop{
  color: var(--blue);
}
.center_shop_btn{
  margin-top: 4px;
  color: white;
  padding: 8px 12px;
  background: var(--pink);
  border-radius: 4px;
  width: fit-content;
  width: -moz-fit-content;
}
.shop_price_text_right{
  font-family: OpenSans-Bold;
  font-size: 14px;
  color: var(--red);
  margin-top: 2px;
  margin-left: 6px;
}
.btn_buy_absolut{
  position: absolute;
  bottom: 12px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%);
}
.buy_text_another{
  font-size: 14px;
  line-height: 20px;
  margin-top: 44px;
  text-align: center;

  color: #98AFB7;

  opacity: 0.9;
}
.payment_result_div{
  
}
@media (max-width: 768px) {
  .buy-grid-container{
    height: auto;
  }
  .buy-title-container{
    margin-top: 10vw;
  }
  .confirm_buy_btn{
    left: 16px;
    max-width: 100%;
    bottom: 16px;
  }
  .confirm_container{
      max-width: 100%;
      
  }
  
  .buy_plashka{
    width: calc(100%/3 - 4px);
  }
  .buy_container{
    height: 176px;
    padding-left: 0px;
  }
  .left_plashka{
    border-radius: 4px 0px 0px 4px;
  }
  .center_plashka{
    width: calc(100%/3 + 8px);
  }
  .result_payment_btn{
      padding: 16px 0px;
      position: fixed;
      bottom: 16px;
      left: 16px;
      right: 16px;
      max-width: 568px;
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
  }
}