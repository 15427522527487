.account-content__item {
  margin-bottom: 30px;
  flex: 0 0 33.3333333333%;
  /*width: 33.3333333333%;*/
  padding-right: 30px;
  /* max-width: 320px; */
  cursor: pointer;
}

.account-content__item>div:first-of-type:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.account-content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 32px;
  margin: 0;
  border-radius: 16px;
  width: 100%;
}

.account-content__photo {
  width: 100%;
  max-height: 256px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.account-info {
  display: flex;
  border-bottom: 4px solid var(--grey);
}

.account-info__photo {
  border-radius: 100%;
  margin-right: 54px;
  margin-bottom: 30px;
  flex-shrink: 0;
}

.account-info__username {
  cursor: pointer;
  margin-bottom: 25px;
  display: inline-block;
}

.account-info__username>span {
  color: var(--main);
}




.account-info__arrow {
  /* background: url(../icons/up.svg) no-repeat center; */
  width: 14px;
  background-color: white;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  transition: .4s transform;
  transform: rotate(180deg);
}

.account-info__arrow_clicked {
  transform: rotate(0deg);
}

.account-info__actions {
  list-style: none;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 21px 6px 21px;
  border-bottom: 4px solid var(--grey);
}

.account-info__select-item {
  padding-left: 17px;
  padding-right: 17px;
}

.account-info__action {
  padding: 15px 0 15px 16px;
  color: white;
}

/* .account-info__action_add {
        background: url(../icons/add.svg) no-repeat 0 50%; } */
.account-info__action_remove {
  color: var(--red);
  /* background: url(../icons/remove.svg) no-repeat 0 50%; } */
}

.account-info__desc p {
  margin-bottom: 28px;
}

.account-info__desc a {
  color: var(--main);
}

.account-info__desc-more {
  display: none;
}

.account-info__tooltip {
  width: 426px;
  padding: 0;
  max-height: 312px;
  overflow: auto;
}

@media (max-width: 768px) {
  .account-content__item>div:first-of-type:hover {
    box-shadow: none;
  }

  .account-content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
    margin: 0 -22px;
    border-radius: 16px;
    width: 100%;
  }

  .account-info {
    border-bottom-width: 2px;
  }

  .account-info__photo {
    width: 72px;
    height: 72px;
    margin-right: 12px;
  }

  .account-info__username {
    font-size: 16px;
    text-transform: initial;
    margin-bottom: 20px;
    position: initial;
  }

  .account-info__desc {
    font-size: 14px;
    height: 21px;
    overflow: hidden;
  }

  .account-info__desc-more {
    display: inline;
    font-size: 14px;
    cursor: pointer;
    opacity: .5;
  }

  .account-info__tooltip {
    width: calc(100% - 44px);
    top: initial;
    margin: 20px 22px;
  }

  .account-info__actions {
    font-size: 14px;
    border-bottom-width: 2px;
  }
}

.account-score {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 124px;
  border-bottom: 4px solid var(--grey);
  transition: .4s margin-bottom;
}

.account-score__counts {
  display: flex;
}

.account-score__count {
  margin-right: 40px;
}

.account-score__value {
  font-family: OpenSans-Bold;
  display: block;
  font-size: 21px;
  font-weight: 900;
}

.account-score__btn {
  width: 325px;
}

.account-score__in-progress {
  line-height: 0;
  font-size: 14px;
  display: block;
  position: relative;
  top: 14px;
  text-align: center;
}

.account-score__in-progress b {
  font-weight: 900;
}

.account-score__in-progress_done b {
  color: var(--green);
}

.account-score__tooltip-wrap.total-tooltip__wrapper {
  display: block;
  padding-left: 35px;
  margin-top: 37px;
}

.account-score__tooltip-arrow.total-tooltip__arrow {
  right: 220px;
  transform: translateX(50%) translateY(14px);
}
.mobile_width_order{
  width: 33.333333%;
}
@media (max-width: 768px) {
  .account-content__item {
    margin-bottom: 30px;
    flex: none;
    width: 100%;
    padding-right: 1px;
    /* max-width: 320px; */
    cursor: pointer;
  }

  .account-score {
    flex-direction: column;
    font-size: 12px;
    margin-top: 16px;
    border-bottom: none;
    justify-content: initial;
    align-items: initial;
  }

  .account-score__value {
    font-size: 14px;
  }

  .account-score__counts {
    margin-bottom: 18px;
  }

  .account-score__count {
    margin-right: 0;
    width: calc(100% / 3);
  }

  .account-score__add,
  .account-score__btn {
    width: 100%;
  }

  .account-score__in-progress {
    font-size: 12px;
  }

  .account-score__tooltip-wrap.total-tooltip__wrapper {
    padding-left: 22px;
    padding-bottom: 0;
  }

  .account-score__tooltip-arrow.total-tooltip__arrow {
    right: 50%;
  }
}

.account-content-loader {
  display: flex;
  flex-wrap: wrap;
  padding-top: 152px;
  width: 100%;
}


.account-content__item>div:first-of-type {
  position: relative;
  border: 1px solid #EFEFEF;
  border-radius: 16px;
  transition: all .3s;
}


.account-content__social-actions {
  display: flex;
  color: #808080;
  padding: 16px;
  justify-content: space-between;
  font-size: 12px;
}

.account-content__social-actions span {
  display: flex;
  align-items: center;
}

.account-content__like::before,
.account-content__comment::before,
.account-content__warn::before {
  content: '';
  display: inline-block;
  margin-right: 5px;
}

.account-content__like::before {
  background: url(../custom_icons/likes.svg) no-repeat center;
  width: 16px;
  height: 16px;
}

.account-content__comment::before {
  background: url(../custom_icons/comments.svg) no-repeat center;
  width: 13px;
  height: 13px;
}

.account-content__warn::before {
  background: url(../custom_icons/warn.svg) no-repeat center;
  width: 16px;
  height: 16px;
}

.account-content__progress {
  display: none;
  font-size: 14px;
  padding: 0 16px 20px 16px;
}

.account-content__progress_done::before {
  content: '';
  background: url(../custom_icons/done.svg) no-repeat center;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: text-top;
  margin-right: 8px;
}

.account-content__progress_promo {
  text-transform: uppercase;
  color: var(--main);
  cursor: pointer;
  font-weight: 700;
}

.account-content__no-photo {
  margin: 82px auto;
  text-align: center;
  font-size: 21px;
  color: #8B8E99;
}

.account-content__no-photo-text {
  display: block;
  margin-top: 22px;
}

.account-content__video-icon {
  border-radius: 100%;
  width: 32px;
  height: 32px;
  background: #fff url(../custom_icons/video.svg) no-repeat center scroll;
  background-size: 18px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 7px;
  right: 7px;
}

@media (max-width: 768px) {
  .account-content__item {
    margin-bottom: 1px;
  }

  .account-content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
    margin: 0 -22px;
    width: 100vw;
  }

  .account-content__item>div:first-of-type {
    border: none;
    border-radius: 0;
  }

  .account-content__photo {
    width: 100%;
    max-height: calc(100vw / 3 - 0.9px);
    ;
    object-fit: cover;
    border-radius: 0;
  }

  .account-content__no-photo {
    font-size: 14px;
    margin: 28px auto;
  }

  .account-content__no-photo img {
    width: 58px;
  }

  .account-content__social-actions {
    display: none;
  }

  .account-content__progress {
    font-size: 12px;
    padding: 0;
    margin-top: 10px;
    padding-top: 6px;
  }

  .account-content__progress_done::before {
    width: 12px;
    height: 12px;
    background-size: 12px;
    margin-right: 8px;
  }
}

.total-tooltip {
  display: none;
}

.total-tooltip__wrapper {
  background: #F7F8FD;
  padding: 25px 40px;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 99;
  margin-top: 31px;
  border-top: 2px solid #DAE0F5;
  border-bottom: 2px solid #DAE0F5;
  display: flex;
  margin-bottom: 80px;
  align-items: flex-start;
  cursor: auto;
}

.total-tooltip__close {
  display: block;
  width: 24px;
  height: 24px;
  /* background: url(../icons/close-tooltip.svg) no-repeat center; */
  position: absolute;
  top: 12px;
  right: 42px;
  cursor: pointer;
  transition: .2s background-image;
}

.total-tooltip__arrow {
  display: block;
  width: 50px;
  height: 25px;
  position: absolute;
  z-index: 900;
  overflow: hidden;
  transform: translateX(250%) translateY(8px);
}

.total-tooltip__arrow::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  background: #F7F8FD;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 100%;
  left: 50%;
  border: 2px solid #DAE0F5;
}

.account-total {
  font-size: 14px;
}

.account-total__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 7px;
}

.account-total__item {
  flex-shrink: 0;
  background: #fff;
  border-radius: 8px;
  padding: 30px 22px;
  width: 186px;
  margin-right: 30px;
  margin-bottom: 30px;
  font-size: 21px;
  font-weight: 900;
}

.account-total__heading {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.account-total__value_ordered {
  color: var(--warn);
}

.account-total__value_done {
  color: var(--green);
}

.jsutify_space {
  display: flex;
  justify-content: space-between;
}

.left_m_0 {
  margin-left: 0px;
}

.size_16 {
  font-size: 16px;
}

.top_a_0 {
  top: 0;
}

.left_m_50 {
  margin-left: 50%
}

.add-subscribe-form {
  display: flex;
  margin-top: 0px;
}

.add-subscribe-form__item {
  width: 100%;
  margin-bottom: 21px;
  padding-right: 32px;
}

.add-subscribe-form__item:last-child {
  padding-right: 0;
}

.add-subscribe-form__label {
  margin-bottom: 14px;
}

.add-subscribe-form__label>.tooltip__container {
  margin-top: 26px;
  top: 100%;
  padding: 16px;
}

.add-subscribe-form__input {
  background-color: #fff;
  margin-top: 12px;
}

.add-subscribe-form__sex-buttons {
  margin-top: 12px;
  margin-bottom: 52px;
  width: 100%;
}

.add-subscribe-form__cost {
  display: block;
  margin-top: 6px;
}

.add-subscribe-form__cost b {
  font-weight: 900;
}

.add-subscribe-form__preview-btn {
  margin-top: 52px;
  text-align: right;
}


@media (max-width: 768px) {
  .add-subscribe-form {
    margin-top: 21px;
    font-size: 14px;
    flex-direction: column;
  }

  .add-subscribe-form__item {
    width: 100%;

    padding-right: 0;
  }

  .add-subscribe-form__input {
    margin-top: 8px;
  }

  .add-subscribe-form__preview-btn {
    display: none;
  }

  .add-subscribe-form__cost {
    position: absolute;
    bottom: 85px;
    margin-left: -22px;
    width: 100%;
    text-align: center;
  }
}

.input {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #EBECF1;
  width: 100%;
  height: 48px;
  padding: 0 8px;
}

.input:focus {
  background-color: #fff;
  border-color: var(--blue);
}

@media (max-width: 768px) {
  .input {
    height: 40px;
  }
}

input.input__error:focus {
  border-color: var(--red);
}

@media (max-width: 768px) {
  .total-tooltip__wrapper {
    padding: 25px 22px;
  }

  .total-tooltip__close {
    right: 22px;
  }
}

.add-subscribe-form {
  display: flex;
  margin-top: 21px;
}

.add-subscribe-form__label {
  margin-bottom: 14px;
}

.add-subscribe-form__label>.tooltip__container {
  margin-top: 26px;
  top: 100%;
  padding: 16px;
}

.add-subscribe-form__input {
  background-color: #fff;
  margin-top: 12px;
}

.add-subscribe-form__sex-buttons {
  margin-top: 12px;
  margin-bottom: 52px;
  width: 100%;
}

.add-subscribe-form__cost {
  display: block;
  margin-top: 6px;
}

.add-subscribe-form__cost b {
  font-weight: 900;
}

.add-subscribe-form__preview-btn {
  margin-top: 52px;
  text-align: right;
}

.rate-content__tooltip {
  display: block;
  margin-left: auto;
}

.rate-content__tooltip-wrapper {
  bottom: 35px;
  left: initial;
  right: 0;
  transform: translateX(26px);
  width: 240px;
}

.rate-content__tooltip-arrow {
  left: initial;
  right: 0;
  transform: translateX(-12px);
}

.follower_price {
  margin-top: -3px;
  margin-left: 16px;
  font-weight: 900;
  font-family: OpenSans-Bold;
}

.bot_m_0 {
  margin-bottom: 0px;
}


.tooltip-not_enought_coins {
  bottom: -40px;
  /* margin-right: 10px; */
  width: 288px;
  right: 80px;
  padding: 15px;
  display: block;
}

.tooltip-session_error {
  bottom: -160px;
  margin-right: 10px;
  width: 288px;
  padding: 15px;
  display: block;
}

@media (max-width: 768px) {

  .tooltip-not_enought_coins {
    bottom: -10px;
    width: 92vw;
    padding: 15px;
    left: 0;
    position: absolute;
  }

  .rate-content__tooltip-wrapper {
    transform: translateX(15px);
  }
}

.follow-tooltip-coins {
  top: 0;
  left: 0;
  transform: rotate(180deg);
  margin-top: -25px;
  margin-right: 20px;
}

.private-profile {
  border: 2px solid #d71d53;
  border-radius: 10px;
  color: #d71d53;
  text-align: left;
  margin-bottom: 16px;
  padding: 16px;
  font-size: 14px;
  margin-right: 30px;
  line-height: 150%;
}

@media (max-width: 768px) {
  .private-profile {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

.arrow_buy_likes {
  top: 0;
  transform: rotate(180deg);
  margin-top: -25px;
  margin-right: 20px;
}

.font_l_16 {
  font-size: 16px;
}

.margin_l_0 {
  margin-bottom: 0px;
}

.margin_l_m_0 {
  margin-left: 0px;
}

.form-order {
  background: #fff;
  width: 288px;
  padding: 22px 16px 32px 18px;
  margin-top: 25px;
  flex-shrink: 0;
}

.form-order__wrapper {
  margin-bottom: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.form-order__wrapper .tooltip-bottom {
  top: 100%;
  width: 100%;
}

.form-order__heading {
  margin-bottom: 28px;
}

.form-order__score {
  display: flex;
  justify-content: space-between;
}

.form-order__sum {
  font-family: OpenSans-Bold;
  font-weight: 900;
}

.form-order__btn {
  margin-top: 32px;
}

.form-order__input {
  border-radius: 8px;
  background-color: var(--grey);
  border: 1px solid #F5F5F8;
  width: 100%;
  height: 48px;
  padding: 0 8px;
}

label.form-order__wrapper:last-of-type {
  border-bottom: 4px solid var(--grey);
  padding-bottom: 16px;
  height: 64px;
}

.mobile_click {
  display: block;
}
.mobile_min_likes_2{
  opacity: 0.5;
  font-weight: 400;
  font-size: 12px;
  color: #000;
  margin-bottom: -16px;
}
.mobile_min_likes{
  font-family: OpenSans-Semibold;
  font-weight: 600;
  font-size: 13px;
  color: #000;
}
.mobile_order_title{
  font-weight: 400;
  font-size: 14px;
  color: #000;
  margin-bottom: 4px;text-align: left;
}
.profile_toast{
    width: calc(100vw - 48px);
    background: rgba(0,0,0,0.8);
    border-radius: 4px;
    padding: 24px;
    
    margin: 0;
    position: fixed;
    bottom: 140px;
    left: 24px;
    z-index: 99999999;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    color: white;
    
}
@media (max-width: 768px) {
  .mobile_click {
    display: block;
    cursor: auto;
  }
  .order_header{
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  .form-order {
    width: 100%;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .form-order__score {
    font-size: 14px;
  }

  .form-order__btn {
    margin-top: 14px;
  }
}

.promotion-div {
  border: 4px solid #F7F8FD;
  border-radius: 6px;
  text-align: center;
  padding-top: 26px;
  padding-bottom: 34px;
}

.promotion-div__title {
  margin-bottom: 20px;
}

.promotion-div__text {
  margin-bottom: 20px;
}

.promotion-div__select {
  max-width: 426px;
  margin: 0 auto;
}

.tooltip_bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.checkbox-label {
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-label .tooltip__container {
  max-width: 214px;
  left: initial;
  right: 0;
  font-size: 16px;
}

.checkbox-label .tooltip-top {
  transform: translateY(-14px);
}

.checkbox-label .tooltip__arrow {
  right: 0;
  left: initial;
  transform: initial;
}

.checkbox-label span::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #F5F5F8;
  transition: .3s border-color;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  background-color: var(--grey);
}

.checkbox-label input[type=checkbox] {
  display: none;
}

.checkbox-label input[type=checkbox]~.form-order__input {
  display: none;
}

.checkbox-label input[type=checkbox]:checked~.form-order__input {
  display: block;
  width: 84px;
}

.checkbox-label input[type=checkbox]:checked+span::before {
  background-color: var(--grey);
  background-image: url(../custom_icons/check.svg);
  background-repeat: no-repeat;
  background-position: center;
}



.mobile-tooltip {
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 99;
  background: var(--grey);
}

.mobile-tooltip-new {

  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background: var(--grey);
}

.mobile-tooltip__header {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: rgba(53, 56, 68, 0.5);
  position: fixed;
  z-index: 999999;
  top: 0;
  padding: 0 20px;
}

.mobile-tooltip__back {
  background: url(../custom_icons/back.svg) no-repeat center;
  width: 24px;
  height: 24px;
  display: block;
  cursor: pointer;
}

.mobile-tooltip__coins {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
}

.mobile-tooltip__coins::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 15px;
  background: url(../custom_icons/mobile-coins.svg) no-repeat center;
  margin-right: 8px;
}

.mobile-tooltip__photo {
  object-fit: cover;
  height: calc(100vw / 1.42);
  width: 100vw;
}

.mobile-tooltip__total {
  padding: 16px 16px 0 16px;
}

.mobile-tooltip__heading {
  font-size: 12px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  opacity: 0.5;
  font-family: OpenSans-SemiBold;
  text-transform: uppercase;
}

.mobile-tooltip__container {
  display: flex;
  font-size: 12px;
  padding-top: 8px;
  justify-content: space-between;
  margin-bottom: 26px;
}

.mobile-tooltip__block {
  width: calc(100% / 3);
}

.mobile-tooltip__block span {
  display: block;
  font-size: 14px;
  font-weight: 900;
}

.mobile-tooltip__block_ordered span {
  color: var(--warn);
}

.mobile-tooltip__block_done span {
  color: var(--green);
}

.up-balance__container {
  display: flex;
}

.mobile-tooltip__block_2 {
  width: calc(100% / 2);
}

.tooltip_weight {
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  color: #353845;
  font-family: OpenSans-Bold;
  margin-bottom: 1px;
}

.tooltip_mobile_likes {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0px;
  color: #000000;
}

.balance,
.buy-coins {
  border: 4px solid;
  border-radius: 8px;
  width: 100%;
}

.balance__inner,
.buy-coins {
  padding: 22px 12px 32px 22px;
}

.balance {
  border-color: var(--grey);
  margin-right: 32px;
}

.balance__inner {
  font-weight: 300;
}

.balance__discount {
  display: flex;
  background-color: var(--grey);
  padding: 36px 12px 28px 22px;
}

.balance__image {
  margin-right: 24px;
}

.balance__text {
  max-width: 200px;
  line-height: 20px;
}

.balance__title {
  margin-bottom: 32px;
}

.buy-coins {
  border-color: transparent;
  background-color: var(--grey);
}

.buy-coins__title {
  margin-bottom: 32px;
}

.buy-coins__input-group {
  display: flex;
}

.buy-coins__label {
  width: 50%;
  max-width: 200px;
  font-size: 14px;
  display: block;
}

.buy-coins__label .tooltip__container {
  top: 17px;
  width: 224px;
  font-size: 16px;
  padding: 15px 18px 14px 18px;
  margin-right: 15px;
}

.buy-coins__label:first-child {
  margin-right: 16px;
  padding-bottom: 38px;
}

.buy-coins__input {
  margin-top: 10px;
  font-size: 16px;
  background-color: #fff;
}

.buy-coins__input_sum {
  font-weight: 900;
}

.buy-coins__input[disabled] {
  background-color: transparent;
}

.buy-coins__discount {
  color: var(--green);
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  padding-top: 2px;
  display: block;
  padding-bottom: 16px;
}

.buy-coins__btn {
  max-width: 200px;
  width: calc(50% - 10px);
}

.left_m16 {
  margin-left: 16px;
}

.bot_header {
  margin-bottom: 10px;
  text-transform: uppercase;
}

.just_space_mobile {
  display: flex;
  justify-content: space-between;
}

.content_normal {
  justify-content: normal;
}

.price_mob_m {
  margin-left: 0px;
  margin-top: 1px;
}

.mob_btn_order {
  margin-bottom: 100px;
  margin-top: 24px;
}

.coins_block {
  display: flex;

}

.mobile-tooltip__photo {
  object-fit: cover;
  height: calc(100vw / 1.42);
  width: 100vw;
}

.input_width {
  width: 100%;
  margin: 0px;
  background: var(--grey);
  margin-right: 16px;
}

.order_type {
  margin-left: 0px;
  margin-top: 10px;
  font-size: 12px;

}

.line-calc-order {

  display: flex;
  justify-content: space-between;

}

.text_price_order {
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 0px;
}

.order_line {
  border: 1px solid #D6D8E6;
  height: 0px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 24px;
}

.text_left_oreder {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-right: 10px;
}

.no_photos {
  width: 100%;
  justify-content: center;
}