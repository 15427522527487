.aside_overflow{
    overflow-y: auto;
}
.nav_border{
    border-bottom: 2px solid #4F5366;
}

.logo_style{
    height: 13px;
    margin-left: 26px;
    margin-top: 22px;
}
.aside {
    flex-shrink: 0;
    width: 100%;
    max-width: 256px;
    z-index: 99999999;
    background-color: #353845;
    color: #fff; 
}

.nav {
    list-style: none;
    margin: 0;
    padding: 0; 
}
.nav__item {
    display: block;
    padding: 24px;
    color: #fff;
    opacity: .7;
    transition: .3s opacity; 
    cursor: pointer;
    font-size: 15px;
}
          
.nav__item_profile {
    max-width: 256px;
    font-weight: 900;
    opacity: 1; 
    cursor: pointer;
    margin-bottom: 0px;
}
.nav__item_active {
    background-color: #53576F;
    border-left: 6px solid var(--main);
    padding-left: 29px;
    opacity: 1; 
}
.nav__profile-photo {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 12px; 
}
.tooltip {
    position: relative; 
}
.tooltip_style{
    padding-top : 7px; display : flex;justify-content: space-between;width: 100%;
}
.tooltip_span{
    opacity : 0.7;overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 130px;display : block;
}
.pad_10{
    padding: 10px
}
.account-info__arrow-menu {
    /* background: url(../icons/up-white.svg) no-repeat center; */
    margin-top: -4px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    transition: .4s transform;
    transform: rotate(180deg); 
}
.tooltip__container {
    display: none;
    position: absolute;
    z-index: 100;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2); 
}
.tooltip__arrow {
    display: block;
    width: 50px;
    height: 25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden; 
}
.tooltip-bottom {
    top: 40px;
    left: 0;
    padding: 0 21px 6px 21px; }
    .tooltip-bottom .tooltip__arrow {
      bottom: 100%; }
      .tooltip-bottom .tooltip__arrow::after {
        top: 100%; }
  .tooltip-top {
    left: 0;
    bottom: 100%; }
    .tooltip-top .tooltip__arrow {
      top: 100%; }
      .tooltip-top .tooltip__arrow::after {
        top: 0; }
  .tooltip-left {
    right: 100%; }
    .tooltip-left .tooltip__arrow {
      width: 25px;
      height: 50px;
      top: 0;
      left: 100%;
      transform: initial; }
      .tooltip-left .tooltip__arrow::after {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        left: 0; 
}
.account-info__tooltip {
    width: 426px;
    padding: 0;
    max-height: 312px;
    overflow: auto; 
}
.aside_header_mobile{
    height: 56px;
    
    background: var(--main);
      display: none;
      visibility: collapse;
}
@media (max-width: 768px) {
    .account-info {
      border-bottom-width: 2px;
       }
       
    .aside_header_mobile {
        height: 56px;
        background: var(--main);
            display: block;
           
            visibility: visible;
          }
      .account-info__photo {
        width: 72px;
        height: 72px;
        margin-right: 12px; }
      .account-info__username {
        font-size: 16px;
        text-transform: initial;
        margin-bottom: 20px;
        position: initial; }
      .account-info__desc {
        font-size: 14px;
        height: 21px;
        overflow: hidden; }
        .account-info__desc-more {
          display: inline;
          font-size: 14px;
          cursor: pointer;
          opacity: .5; }
      .account-info__tooltip {
        width: calc(100% - 44px);
        top: initial;
        margin: 20px 22px; }
      .account-info__actions {
        font-size: 14px;
        border-bottom-width: 2px; } 
}
.transformable-profiles {
    -webkit-transition: height 100ms linear;
    -moz-transition: height 100ms linear;
    -o-transition: height 100ms linear;
    -ms-transition: height 100ms linear;
    transition: height 100ms linear;
    transition:0.1s linear all;
}
.profiles_container{
    opacity:0;height : 0px;border-bottom: 2px solid #4F5366;
}
.add-account-btn{
    display: flex;
    cursor: pointer;
    margin-top: -2px;
      font-weight: 700;
      border-top: 2px solid #4F5366;
}
.account-info__actions {
    list-style: none;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 21px 6px 21px;
    border-bottom: 4px solid var(--grey); }
  .account-info__select-item {
    padding-left: 17px;
    padding-right: 17px; }
  .account-info__action {
    padding-left: 20px;
    color: white; }
    .account-info__action_add {
      /* background: url(../icons/add.svg) no-repeat 0 50%; } */
    }
    .account-info__action_remove {
      color: var(--red);
      /* background: url(../icons/remove.svg) no-repeat 0 50%;  */
}
.bot_20{
    margin-bottom: 20px;
}
.pad_20{
    padding: 24px;
}
.license_text{
    color : white; opacity : 0.7;font-size: 14px;
}
.write_to_us{
    font-size : 12px; opacity : 0.5;margin-top: 24px;
}
.gift_container{
    position: absolute; bottom: 0;
}
.gift-gradient{
    width: 256px;
    height: 64px;
    background: linear-gradient(rgba(35, 36, 46, 0), rgba(35, 36, 46, 1));
  }
  @media (min-width: 0px) {
    .gift_elements{
      display: flex;
    }
    .gift__text_box {
      display: block;
      margin-right: -16px;
      margin-left: 16px;
  }
  
  .gift__text {
    margin-top: 16px;
    margin-bottom: 8px;
      font-size: 14px;
      font-weight: 900;
      line-height: 20px;
  }
  .gift_elements{
    display: flex;
    margin-bottom : 0px;
  }
    .aside__gift {
      display: flex;
      
      padding-top: 20px;
      padding-bottom: 20px; }
      .aside__gift img {
        /* content: url(../icons/aside_mob.svg); */
    width: 80px;
    height: 80px;
    margin-right: px;
    margin-left: -16px;
    margin-top: 12px; }
  
  
      .gift__text_mobile {
        display: block; } 
      
}
@media (max-width: 1024px) {
    .aside {
      position: absolute;
      top: 0;
      left: -256px;
      transition: .4s left;
      z-index: 99999999;
      height: 100%; } 
}
.active_nav{
    background-color: rgb(83, 87, 111);
    border-left: 6px solid var(--main);
    padding-left: 18px;
    opacity: 1;
}
